<template>
  <Page>
    <graphs-component
      :stats="data"
      :periods="periods"
      :brands-prop="brands"
      :origin-categories="categories"
      :warehouses="warehouses"
      :origin-sites="sites"
      :salesmen="salesmen"
      :loading-prop="loading"
      @getData="getData"
    />
  </Page>
</template>

<script>
import graphsComponent from "@/components/modules/analytics/graphs/index.vue";

export default {
  components: {
    graphsComponent
  },
  data() {
    return {
      loading: true,
      warehouses: [],
      sites: [],
      brands: [],
      categories: [],
      salesmen: [],
      data: [],
      periods: []
    };
  },
  created() {
    this.updateTitle(this.$i18n.tc("graph", 2));
  },
  methods: {
    getData(obj) {
      this.loading = true;
      let api_params = `?warehouse_id=${obj.warehouse_id || ""}&period=${
        obj.selected_date_mode
      }`;

      let filter = [];
      if (obj.brands && obj.brands.length) {
        filter.push({
          id: "brands",
          selected: obj.brands
        });
      }
      if (obj.sites && obj.sites.length) {
        let sites = [];
        let slds = [];
        for (let f of obj.sites) {
          if(typeof f == 'number') sites.push(f);
          else slds.push(parseInt(f.substring(1)));
        }
        if (sites.length) {
          filter.push({
            id: "sites",
            selected: sites
          });
        }
        if (slds.length) {
          filter.push({
            id: "slds",
            selected: slds
          });
        }
      }
      if (obj.tlds && obj.tlds.length) {
        filter.push({
          id: "slds",
          selected: obj.tlds
        });
      }
      if (obj.categories && obj.categories.length) {
        filter.push({
          id: "categories",
          selected: obj.categories
        });
      }
      if (obj.salesmen && obj.salesmen.length) {
        filter.push({
          id: "salesmen",
          selected: obj.salesmen
        });
      }
      let dates_array = [
        {
          from: obj.date.from,
          to: obj.date.to
        }
      ];
      if (obj.compare_date && obj.compare_date.from && obj.compare_date.to) {
        dates_array.push({
          from: obj.compare_date.from,
          to: obj.compare_date.to
        });
      }
      filter.push({
        id: "dates",
        selected: dates_array
      });

      api_params += `&filters=${btoa(JSON.stringify(filter))}`;

      this.$axios
        .get("analytics/graph" + api_params)
        .then(res => {
          this.periods = res.data.dataset.periods;
          this.data = res.data.dataset.data;
          this.warehouses = res.data.filters.warehouses;
          this.sites = res.data.filters.sites;
          this.brands = res.data.filters.brands;
          this.categories = res.data.filters.categories;
          this.salesmen = res.data.filters.salesmen;
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
